<template>
  <section class="base-page">
    <PageHeader class="base-shadow"></PageHeader>
    <FilesView
    :showSelectBtn="false" class="file-view"/>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import FilesView from "./FilesView";
export default {
  components: { PageHeader,FilesView },
};
</script>

<style lang="scss" scoped>
.file-view{
    margin-top: 20px;
}
</style>